import React, { useRef, useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { sliderSettings1 } from '../const/attr'

import crossI from '../images/cross-orange.svg'
import rightIcon from '../images/arrowR.svg'
import leftIcon from '../images/arrowL.svg'

const GallerySlider = ({ gallery="farmers", className }) => {

    const slider = useRef();

    const [modal, setModal] = useState(false);

    const data = useStaticQuery(graphql`
        query GallerySliderQuery {
            farmers: wpPost(slug: {eq: "galeria-trznica"}) {
                ...GalleryData
            }
            basic: wpPost(slug: {eq: "galeria-vseobecna"}) {
                ...GalleryData
            }
        }
    `)

    const feeds = (data[gallery]?.acfGalerie ? Object.values(data[gallery].acfGalerie) : []).filter((item)=>!!item);

    const settings = {
        ...sliderSettings1,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 3,
                    dots:true
                }
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 2,
                    dots:true
                }
            },
        ]
    }

    const settingsModal = {
        ...sliderSettings1,
        slidesToShow: 1,
        nextArrow: <img src={rightIcon}/>, 
        prevArrow: <img src={leftIcon}/>,
    }

    const handleClick = (i) => {
        slider.current.slickGoTo(i, true)
        setModal(p=>!p)
    }

    return (
        <>
            <div className={"socFeeds infSliderCon gallery " + className}>
                <div className="content infSlider cards">
                    {!!feeds.length ? <Slider {...settings} ref={slider}>
                        {!!feeds.length && feeds.map((item, index)=>{
                                return (
                                    <div key={index} onClick={()=>handleClick(index)}>
                                        <GatsbyImage image={item?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt={item?.localFile?.altText ?? "Ilustracia"} />
                                    </div>
                                )
                            })}
                    </Slider> : ""}
                </div>
            </div>
            <div id="modalGallery" className={'logoB infSlider concrete s ' + (modal ? 'open' : '')}>
                <img src={crossI} onClick={()=>setModal(p=>!p)}/>
                <Slider {...settingsModal} ref={slider}>
                    {feeds.map((img,i)=><div key={i}><GatsbyImage image={img?.localFile?.childrenImageSharp[0]?.gatsbyImageData} alt={img?.localFile?.altText ?? "Ilustracia"} /></div>)}
                </Slider>
            </div>
        </>
    )
}

export default GallerySlider;
